// 导入组件
import Vue from 'vue'
import Router from 'vue-router'
// 登录
// import login from '@/views/login';
// // 首页
import index from '@/views/index'
// /**
//  * 基础菜单
//  */
// // 商品管理
// import Goods from '@/views/goods/Goods';
// // 机器信息管理
// import Machine from '@/views/machine/Machine';
// // 货道信息管理
// import MachineAisle from '@/views/machine/MachineAisle';
// /**
//  * 订单管理
//  */
// // 交易订单
// import Order from '@/views/pay/Order';
// /**
//  * 系统管理
//  */
// // 用户管理
// import user from '@/views/system/user';
// // 菜单管理
// import Module from '@/views/system/Module';
// // 角色管理
// import Role from '@/views/system/Role';
// // 公司管理
// import Dept from '@/views/system/Dept';
// // 系统环境变量
// import Variable from '@/views/system/Variable';
// // 权限管理
// import Permission from '@/views/system/Permission';
// /**
//  * 支付管理
//  */
// // 支付配置信息
// import MachineConfig from '@/views/machine/MachineConfig';
// // 支付配置
// import Config from '@/views/pay/Config';
// /**
//  * 数据监控
//  */
// // 监控查询
// import druidLogin from '@/views/druid/login';

// // 图表界面
// import statistics from '@/views/charts/statistics';

// 启用路由
Vue.use(Router)

export const constantRoutes = [
    // {
    //     path: '/',
    //     name: '',
    //     component: () => import('@/views/login'),
    //     hidden: true,
    //     meta: {
    //         requireAuth: false
    //     }
    // },
    {
        path: '/login',
        name: '登录',
        component: () => import('@/views/login'),
        hidden: true,
        meta: {
            requireAuth: false,
        },
    },
    {
        path: '/profile',
        name: '个人中心',
        component: index,
        iconCls: 'el-icon-tickets',
        children: [
            {
                path: '/profile',
                name: '个人中心',
                component: () =>
                    import('@/views/system/user/profile/index.vue'),
                hidden: true,
            },
        ],
    },
    {
        path: '/mp/view/action',
        name: '新建企业',
        component: () => import('@/views/wxapp/wenjuan/company-input.vue'),
        hidden: true,
        meta: {
            requireAuth: false,
        },
    },
    {
        path: '/mp/view/survey/list',
        name: '小程序问卷列表',
        component: () => import('@/views/wxapp/wenjuan/survey-list.vue'),
        hidden: true,
        meta: {
            requireAuth: false,
        },
    },
    {
        path: '/mp/view/survey/view',
        name: '问卷填写',
        component: () => import('@/views/wxapp/wenjuan/survey-view.vue'),
        hidden: true,
        meta: {
            requireAuth: false,
        },
    },
]

// 省级
export const provincialRoutes = [
    {
        path: '/analysisResult',
        name: '催报页面',
        component: () =>
            import(
                '@/views/provincial/workbench/analysisResult'
            ),
        meta: {
            requireAuth: true,
            activeMenu: '/',
        },
        hidden: true,
    },
    {
        path: '/',
        name: '控制台',
        component: index,
        iconCls: 'el-icon-tickets',
        redirect: '/index',
        children: [
            {
                path: '/index',
                name: '控制台',
                component: () => import('@/views/provincial/workbench/index'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/',
                },
            },
           
            {
                path: '/workbench/historicalReporting',
                name: '历史填报数据',
                component: () =>
                    import('@/views/provincial/workbench/historicalReporting'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/',
                },
                hidden: true,
            },
            {
                path: '/workbench/thisReportting',
                name: '本次填报数据',
                component: () =>
                    import('@/views/provincial/workbench/reportting'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/',
                },
                hidden: true,
            },
            {
                path: '/answerlist/index',
                name: '答案列表',
                component: () =>
                    import('@/views/provincial/questionnaire/answerlist/index'),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/userPlatformInformation',
                name: '用户平台信息',
                component: () =>
                    import(
                        '@/views/provincial/questionnaire/userPlatformInformation'
                    ),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/company/user',
                name: '企业联系人',
                component: () => import('@/views/provincial/company/user'),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/company/group',
                name: '企业组列表',
                component: () =>
                    import('@/views/provincial/company/companyGroup/index'),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/userList/index',
                name: '管理用户列表',
                component: () => import('@/views/provincial/user/userList'),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/district/index',
                name: '地区管理',
                component: () => import('@/views/provincial/district/index'),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/reportManagement/index',
                name: '报告管理',
                component: () =>
                    import('@/views/provincial/reportManagement/index'),
                meta: {
                    requireAuth: true,
                },
            },
        ],
    },
    {
        path: '/questionnaire',
        name: '模板列表',
        redirect: '/questionnaire/formboard',
        component: index,
        meta: {
            requireAuth: true,
        },
        children: [
            {
                path: '/questionnaire/formboard',
                name: '模板列表',
                component: () =>
                    import('@/views/provincial/questionnaire/formboard/index'),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/questionnaire/formboard/add',
                name: '新增模板',
                component: () =>
                    import(
                        '@/views/provincial/questionnaire/formboard/report/add'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/questionnaire/formboard',
                },
                hidden: true,
            },
            {
                path: '/questionnaire/formboard/preview',
                name: '预览模板',
                component: () =>
                    import(
                        '@/views/provincial/questionnaire/formboard/report/previewBox'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/questionnaire/formboard',
                },
                hidden: true,
            },
            {
                path: '/questionnaire/formboard/editContent',
                name: '编辑内容',
                component: () =>
                    import(
                        '@/views/provincial/questionnaire/formboard/report/editContent'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/questionnaire/formboard',
                },
                hidden: true,
            },
        ],
    },
    {
        path: '/list',
        redirect: '/list/index',
        name: '问卷列表',
        component: index,
        meta: {
            requireAuth: true,
        },
        children: [
            {
                path: '/list/index',
                name: '问卷列表',
                component: () =>
                    import('@/views/provincial/questionnaire/list/index'),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/list/add',
                name: '问卷信息',
                component: () =>
                    import('@/views/provincial/questionnaire/list/report/add'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/list/index',
                },
                hidden: true,
            },
            {
                path: '/list/edit',
                name: '问卷编辑',
                component: () =>
                    import('@/views/provincial/questionnaire/list/report/edit'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/list/index',
                },
                hidden: true,
            },
            {
                path: '/list/questionnaire/formboard/preview',
                name: '预览模板',
                component: () =>
                    import(
                        '@/views/provincial/questionnaire/formboard/report/previewBox'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/list/index',
                },
                hidden: true,
            },
            {
                path: '/list/analysisResult',
                name: '分析结果',
                component: () =>
                    import(
                        '@/views/provincial/questionnaire/list/analysisResult'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/list/index',
                },
                hidden: true,
            },
        ],
    },
    {
        path: '/businessPushLog',
        name: '商务部推送日志',
        // component: () => import('@/views/provincial/questionnaire/businessPushLog/index'),
        redirect: '/businessPushLog/index',
        component: index,
        meta: {
            requireAuth: true,
        },
        children: [
            {
                path: '/businessPushLog/index',
                name: '商务部推送日志',

                component: () =>
                    import(
                        '@/views/provincial/questionnaire/businessPushLog/index'
                    ),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/businessPushLog/xml',
                name: 'XML数据',

                component: () =>
                    import(
                        '@/views/provincial/questionnaire/businessPushLog/xml'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/businessPushLog/index',
                },
                hidden: true,
            },
            {
                path: '/businessPushLog/preview',
                name: '商务部推送日志预览',
                component: () =>
                    import(
                        '@/views/provincial/questionnaire/businessPushLog/previewBox'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/businessPushLog/index',
                },
                hidden: true,
            },
        ],
    },
    {
        path: '/company',
        name: '企业列表',
        redirect: '/company/index',
        component: index,
        meta: {
            requireAuth: true,
        },
        children: [
            {
                path: '/company/index',
                name: '企业列表',
                component: () =>
                    import('@/views/provincial/company/companyList/index'),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/company/add',
                name: '新增企业',
                component: () =>
                    import('@/views/provincial/company/companyList/report/add'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/company/index',
                },
                hidden: true,
            },
            {
                path: '/company/preview',
                name: '企业预览',
                component: () =>
                    import(
                        '@/views/provincial/company/companyList/report/preview'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/company/index',
                },
                hidden: true,
            },
            {
                path: '/company/information',
                name: '企业填报情况',
                component: () =>
                    import(
                        '@/views/provincial/company/companyList/report/information'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/company/index',
                },
                hidden: true,
            },
            {
                path: '/company/workbench/thisReportting',
                name: '填报数据',
                component: () =>
                    import('@/views/provincial/workbench/reportting'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/company/index',
                },
                hidden: true,
            },
        ],
    },
    {
        path: '/smallProgramCompany',
        name: '小程序企业列表',
        redirect: '/smallProgramCompany/index',
        component: index,
        meta: {
            requireAuth: true,
        },
        children: [
            {
                path: '/smallProgramCompany/index',
                name: '小程序企业列表',
                component: () =>
                    import(
                        '@/views/provincial/company/smallProgramCompany/index'
                    ),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/smallProgramCompany/add',
                name: '新增小程序企业',
                component: () =>
                    import('@/views/provincial/company/companyList/report/add'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/smallProgramCompany/index',
                },
                hidden: true,
            },
            {
                path: '/smallProgramCompany/preview',
                name: '小程序企业预览',
                component: () =>
                    import(
                        '@/views/provincial/company/companyList/report/preview'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/smallProgramCompany/index',
                },
                hidden: true,
            },
            {
                path: '/smallProgramCompany/information',
                name: '小程序企业填报情况',
                component: () =>
                    import(
                        '@/views/provincial/company/companyList/report/information'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/smallProgramCompany/index',
                },
                hidden: true,
            },
            {
                path: '/smallProgramCompany/workbench/thisReportting',
                name: '小程序企业填报数据',
                component: () =>
                    import('@/views/provincial/workbench/reportting'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/smallProgramCompany/index',
                },
                hidden: true,
            },
        ],
    },
    {
        path: '/industrialChainEnterprise',
        name: '产业链企业',
        redirect: '/industrialChainEnterprise/index',
        component: index,
        meta: {
            requireAuth: true,
        },
        children: [
            {
                path: '/industrialChainEnterprise/index',
                name: '产业链企业',
                component: () =>
                    import(
                        '@/views/provincial/company/industrialChainEnterprise/index'
                    ),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/industrialChainEnterprise/add',
                name: '新增产业链企业',
                component: () =>
                    import('@/views/provincial/company/companyList/report/add'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/industrialChainEnterprise/index',
                },
                hidden: true,
            },
            {
                path: '/industrialChainEnterprise/preview',
                name: '产业链企业预览',
                component: () =>
                    import(
                        '@/views/provincial/company/companyList/report/preview'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/industrialChainEnterprise/index',
                },
                hidden: true,
            },
            {
                path: '/industrialChainEnterprise/information',
                name: '产业链企业填报情况',
                component: () =>
                    import(
                        '@/views/provincial/company/companyList/report/information'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/industrialChainEnterprise/index',
                },
                hidden: true,
            },
        ],
    },
    {
        path: '/smallProgramUser',
        name: '管理小程序用户列表',
        component: index,
        redirect: '/smallProgramUser/index',
        meta: {
            requireAuth: true,
        },
        children: [
            {
                path: '/smallProgramUser/index',
                name: '管理小程序用户列表',
                component: () =>
                    import('@/views/provincial/user/smallProgramUser'),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/smallProgramUser/previewSmall',
                name: '管理小程序用户预览',
                component: () => import('@/views/provincial/user/previewSmall'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/smallProgramUser/index',
                },
                hidden: true,
            },
        ],
    },
    {
        path: '/trainingActivity',
        name: '培训活动',
        redirect: '/trainingActivity/index',
        component: index,
        meta: {
            requireAuth: true,
        },
        children: [
            {
                path: '/trainingActivity/index',
                name: '培训活动',
                component: () =>
                    import(
                        '@/views/provincial/training/trainingActivity/index'
                    ),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/trainingActivity/add',
                name: '编辑培训活动',
                component: () =>
                    import(
                        '@/views/provincial/training/trainingActivity/report/add'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/trainingActivity/index',
                },
                hidden: true,
            },
            {
                path: '/trainingActivity/preview',
                name: '查看报名表',
                component: () =>
                    import(
                        '@/views/provincial/training/trainingActivity/report/preview'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/trainingActivity/index',
                },
                hidden: true,
            },
        ],
    },
    {
        path: '/trainingCourse',
        name: '培训课程',
        redirect: '/trainingCourse/index',
        component: index,
        meta: {
            requireAuth: true,
        },
        children: [
            {
                path: '/trainingCourse/index',
                name: '培训课程',
                component: () =>
                    import('@/views/provincial/training/trainingCourse/index'),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/trainingCourse/add',
                name: '新增培训课程',
                component: () =>
                    import(
                        '@/views/provincial/training/trainingCourse/report/add'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/trainingCourse/index',
                },
                hidden: true,
            },
        ],
    },
    {
        path: '/article',
        name: '文章管理',
        redirect: '/article/index',
        component: index,
        meta: {
            requireAuth: true,
        },
        children: [
            {
                path: '/article/index',
                name: '文章管理',
                component: () => import('@/views/provincial/article/index'),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/article/add',
                name: '新增文章',
                component: () =>
                    import('@/views/provincial/article/report/add'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/article/index',
                },
                hidden: true,
            },
        ],
    },
    // {
    //     path: '/reportManagement/index',
    //     name: '报告管理',
    //     component: () =>
    //         import('@/views/provincial/reportManagement/index'),
    //     meta: {
    //         requireAuth: true,
    //     },
    // },
    {
        path: '/reportManagement',
        name: '报告管理',
        redirect: '/reportManagement/index',
        component: index,
        meta: {
            requireAuth: true,
        },
        children: [
            {
                path: '/reportManagement/index',
                name: '报告管理',
                component: () => import('@/views/provincial/reportManagement/index'),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/reportManagement/cityIndex',
                name: '地市报告',
                component: () =>
                    import('@/views/provincial/reportManagement/cityReport'),
                meta: {
                    requireAuth: true
                },
            },
        ],
    },
    {
        path: '/dataScreenManagement',
        name: '大屏管理',
        redirect: '/dataScreenManagement/index',
        component: index,
        meta: {
            requireAuth: true,
        },
        children: [
            {
                path: '/dataScreenManagement/index',
                name: '大屏管理',
                component: () => import('@/views/provincial/dataScreenManagement/index'),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: '/dataScreenManagement/add',
                name: '新增大屏数据',
                component: () =>
                    import('@/views/provincial/dataScreenManagement/report/add'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/dataScreenManagement/index',
                },
                hidden: true,
            },
        ],
    },
]
// 市级
export const cityRoutes = [
    {
        path: '/',
        name: '控制台',
        component: index,
        iconCls: 'el-icon-tickets',
        redirect: '/index',
        children: [
            {
                path: '/index',
                name: '控制台',
                component: () => import('@/views/city/workbench/index'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/',
                },
            },
            {
                path: '/analysisResult',
                name: '分析结果',
                component: () =>
                    import('@/views/city/questionnaire/list/analysisResult'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/',
                },
                hidden: true,
            },
            {
                path: '/workbench/historicalReporting',
                name: '历史填报数据',
                component: () =>
                    import('@/views/city/workbench/historicalReporting'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/',
                },
                hidden: true,
            },
            {
                path: '/workbench/thisReportting',
                name: '本次填报数据',
                component: () => import('@/views/city/workbench/reportting'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/',
                },
                hidden: true,
            },
            // {
            //     path: '/answerlist/index',
            //     name: '答案列表',
            //     component: () =>
            //         import('@/views/city/questionnaire/answerlist/index'),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
            // {
            //     path: '/userPlatformInformation',
            //     name: '用户平台信息',
            //     component: () =>
            //         import(
            //             '@/views/city/questionnaire/userPlatformInformation'
            //         ),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
            // {
            //     path: '/businessPushLog/index',
            //     name: '商务部推送日志',
            //     component: () =>
            //         import('@/views/city/questionnaire/businessPushLog/index'),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
            // {
            //     path: '/company/user',
            //     name: '企业联系人',
            //     component: () => import('@/views/city/company/user'),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
            // {
            //     path: '/company/group',
            //     name: '企业组列表',
            //     component: () =>
            //         import('@/views/city/company/companyGroup/index'),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
            // {
            //     path: '/userList/index',
            //     name: '管理用户列表',
            //     component: () => import('@/views/city/user/userList'),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
            // {
            //     path: '/district/index',
            //     name: '地区管理',
            //     component: () => import('@/views/city/district/index'),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
            // {
            //     path: '/reportManagement/index',
            //     name: '报告管理',
            //     component: () => import('@/views/city/reportManagement/index'),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
        ],
    },
		
    {
			path: '/reportManagement',
			name: '报告管理',
			redirect: '/reportManagement/index',
			component: index,
			meta: {
					requireAuth: true,
			},
			children: [
					{
							path: '/reportManagement/index',
							name: '报告管理',
							component: () => import('@/views/provincial/reportManagement/index'),
							meta: {
									requireAuth: true,
							},
					},
					{
							path: '/reportManagement/cityIndex',
							name: '地市报告',
							component: () =>
									import('@/views/provincial/reportManagement/cityReport'),
							meta: {
									requireAuth: true
							},
					},
			],
	},
    // {
    //     path: '/questionnaire',
    //     name: '模板列表',
    //     redirect: '/questionnaire/formboard',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/questionnaire/formboard',
    //             name: '模板列表',
    //             component: () =>
    //                 import('@/views/city/questionnaire/formboard/index'),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/questionnaire/formboard/add',
    //             name: '新增模板',
    //             component: () =>
    //                 import('@/views/city/questionnaire/formboard/report/add'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/questionnaire/formboard',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/questionnaire/formboard/preview',
    //             name: '预览模板',
    //             component: () =>
    //                 import(
    //                     '@/views/city/questionnaire/formboard/report/previewBox'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/questionnaire/formboard',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/questionnaire/formboard/editContent',
    //             name: '编辑内容',
    //             component: () =>
    //                 import(
    //                     '@/views/city/questionnaire/formboard/report/editContent'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/questionnaire/formboard',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/list',
    //     redirect: '/list/index',
    //     name: '问卷列表',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/list/index',
    //             name: '问卷列表',
    //             component: () =>
    //                 import('@/views/city/questionnaire/list/index'),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/list/add',
    //             name: '问卷信息',
    //             component: () =>
    //                 import('@/views/city/questionnaire/list/report/add'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/list/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/list/questionnaire/formboard/preview',
    //             name: '预览模板',
    //             component: () =>
    //                 import(
    //                     '@/views/city/questionnaire/formboard/report/previewBox'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/list/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/list/analysisResult',
    //             name: '分析结果',
    //             component: () =>
    //                 import('@/views/city/questionnaire/list/analysisResult'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/list/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/company',
    //     name: '企业列表',
    //     redirect: '/company/index',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/company/index',
    //             name: '企业列表',
    //             component: () =>
    //                 import('@/views/city/company/companyList/index'),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/company/add',
    //             name: '新增企业',
    //             component: () =>
    //                 import('@/views/city/company/companyList/report/add'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/company/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/company/preview',
    //             name: '预览',
    //             component: () =>
    //                 import('@/views/city/company/companyList/report/preview'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/company/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/company/information',
    //             name: '填报情况',
    //             component: () =>
    //                 import(
    //                     '@/views/city/company/companyList/report/information'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/company/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/company/workbench/thisReportting',
    //             name: '填报数据',
    //             component: () =>
    //                 import('@/views/provincial/workbench/reportting'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/company/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/smallProgramCompany',
    //     name: '小程序企业列表',
    //     redirect: '/smallProgramCompany/index',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/smallProgramCompany/index',
    //             name: '小程序企业列表',
    //             component: () =>
    //                 import('@/views/city/company/smallProgramCompany/index'),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/smallProgramCompany/add',
    //             name: '新增企业',
    //             component: () =>
    //                 import('@/views/city/company/companyList/report/add'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/smallProgramCompany/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/smallProgramCompany/preview',
    //             name: '预览',
    //             component: () =>
    //                 import('@/views/city/company/companyList/report/preview'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/smallProgramCompany/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/smallProgramCompany/information',
    //             name: '填报情况',
    //             component: () =>
    //                 import(
    //                     '@/views/city/company/companyList/report/information'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/smallProgramCompany/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/industrialChainEnterprise',
    //     name: '产业链企业',
    //     redirect: '/industrialChainEnterprise/index',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/industrialChainEnterprise/index',
    //             name: '产业链企业',
    //             component: () =>
    //                 import(
    //                     '@/views/city/company/industrialChainEnterprise/index'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/industrialChainEnterprise/add',
    //             name: '新增企业',
    //             component: () =>
    //                 import('@/views/city/company/companyList/report/add'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/industrialChainEnterprise/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/industrialChainEnterprise/preview',
    //             name: '预览',
    //             component: () =>
    //                 import('@/views/city/company/companyList/report/preview'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/industrialChainEnterprise/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/industrialChainEnterprise/information',
    //             name: '填报情况',
    //             component: () =>
    //                 import(
    //                     '@/views/city/company/companyList/report/information'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/industrialChainEnterprise/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/smallProgramUser',
    //     name: '管理小程序用户列表',
    //     component: index,
    //     redirect: '/smallProgramUser/index',
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/smallProgramUser/index',
    //             name: '管理小程序用户列表',
    //             component: () => import('@/views/city/user/smallProgramUser'),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/smallProgramUser/previewSmall',
    //             name: '管理小程序用户预览',
    //             component: () => import('@/views/city/user/previewSmall'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/smallProgramUser/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/trainingActivity',
    //     name: '培训活动',
    //     redirect: '/trainingActivity/index',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/trainingActivity/index',
    //             name: '培训活动',
    //             component: () =>
    //                 import('@/views/city/training/trainingActivity/index'),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/trainingActivity/add',
    //             name: '编辑培训活动',
    //             component: () =>
    //                 import('@/views/city/training/trainingActivity/report/add'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/trainingActivity/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/trainingCourse',
    //     name: '培训课程',
    //     redirect: '/trainingCourse/index',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/trainingCourse/index',
    //             name: '培训课程',
    //             component: () =>
    //                 import('@/views/city/training/trainingCourse/index'),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/trainingCourse/add',
    //             name: '新增培训课程',
    //             component: () =>
    //                 import('@/views/city/training/trainingCourse/report/add'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/trainingCourse/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/article',
    //     name: '文章管理',
    //     redirect: '/article/index',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/article/index',
    //             name: '文章管理',
    //             component: () => import('@/views/city/article/index'),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/article/add',
    //             name: '新增文章',
    //             component: () => import('@/views/city/article/report/add'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/article/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
]
// 区县级
export const districtRoutes = [
    {
        path: '/',
        name: '控制台',
        component: index,
        iconCls: 'el-icon-tickets',
        redirect: '/index',
        children: [
            {
                path: '/index',
                name: '控制台',
                component: () => import('@/views/district/workbench/index'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/',
                },
            },
            {
                path: '/analysisResult',
                name: '分析结果',
                component: () =>
                    import(
                        '@/views/district/questionnaire/list/analysisResult'
                    ),
                meta: {
                    requireAuth: true,
                    activeMenu: '/',
                },
                hidden: true,
            },
            {
                path: '/workbench/historicalReporting',
                name: '历史填报数据',
                component: () =>
                    import('@/views/district/workbench/historicalReporting'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/',
                },
                hidden: true,
            },
            {
                path: '/workbench/thisReportting',
                name: '本次填报数据',
                component: () =>
                    import('@/views/district/workbench/reportting'),
                meta: {
                    requireAuth: true,
                    activeMenu: '/',
                },
                hidden: true,
            },
            // {
            //     path: '/answerlist/index',
            //     name: '答案列表',
            //     component: () =>
            //         import('@/views/district/questionnaire/answerlist/index'),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
            // {
            //     path: '/userPlatformInformation',
            //     name: '用户平台信息',
            //     component: () =>
            //         import(
            //             '@/views/district/questionnaire/userPlatformInformation'
            //         ),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
            // {
            //     path: '/businessPushLog/index',
            //     name: '商务部推送日志',
            //     component: () =>
            //         import(
            //             '@/views/district/questionnaire/businessPushLog/index'
            //         ),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
            // {
            //     path: '/company/user',
            //     name: '企业联系人',
            //     component: () => import('@/views/district/company/user'),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
            // {
            //     path: '/company/group',
            //     name: '企业组列表',
            //     component: () =>
            //         import('@/views/district/company/companyGroup/index'),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
            // {
            //     path: '/userList/index',
            //     name: '管理用户列表',
            //     component: () => import('@/views/district/user/userList'),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
            // {
            //     path: '/district/index',
            //     name: '地区管理',
            //     component: () => import('@/views/district/district/index'),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
            // {
            //     path: '/reportManagement/index',
            //     name: '报告管理',
            //     component: () =>
            //         import('@/views/district/reportManagement/index'),
            //     meta: {
            //         requireAuth: true,
            //     },
            // },
        ],
    },
		{
			path: '/reportManagement',
			name: '报告管理',
			redirect: '/reportManagement/index',
			component: index,
			meta: {
					requireAuth: true,
			},
			children: [
					{
							path: '/reportManagement/index',
							name: '报告管理',
							component: () => import('@/views/provincial/reportManagement/index'),
							meta: {
									requireAuth: true,
							},
					},
					{
							path: '/reportManagement/cityIndex',
							name: '地市报告',
							component: () =>
									import('@/views/provincial/reportManagement/cityReport'),
							meta: {
									requireAuth: true
							},
					},
			],
	},
    // {
    //     path: '/questionnaire',
    //     name: '模板列表',
    //     redirect: '/questionnaire/formboard',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/questionnaire/formboard',
    //             name: '模板列表',
    //             component: () =>
    //                 import('@/views/district/questionnaire/formboard/index'),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/questionnaire/formboard/add',
    //             name: '新增模板',
    //             component: () =>
    //                 import(
    //                     '@/views/district/questionnaire/formboard/report/add'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/questionnaire/formboard',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/questionnaire/formboard/preview',
    //             name: '预览模板',
    //             component: () =>
    //                 import(
    //                     '@/views/district/questionnaire/formboard/report/previewBox'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/questionnaire/formboard',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/questionnaire/formboard/editContent',
    //             name: '编辑内容',
    //             component: () =>
    //                 import(
    //                     '@/views/district/questionnaire/formboard/report/editContent'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/questionnaire/formboard',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/list',
    //     redirect: '/list/index',
    //     name: '问卷列表',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/list/index',
    //             name: '问卷列表',
    //             component: () =>
    //                 import('@/views/district/questionnaire/list/index'),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/list/add',
    //             name: '问卷信息',
    //             component: () =>
    //                 import('@/views/district/questionnaire/list/report/add'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/list/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/list/questionnaire/formboard/preview',
    //             name: '预览模板',
    //             component: () =>
    //                 import(
    //                     '@/views/district/questionnaire/formboard/report/previewBox'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/list/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/list/analysisResult',
    //             name: '分析结果',
    //             component: () =>
    //                 import(
    //                     '@/views/district/questionnaire/list/analysisResult'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/list/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/company',
    //     name: '企业列表',
    //     redirect: '/company/index',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/company/index',
    //             name: '企业列表',
    //             component: () =>
    //                 import('@/views/district/company/companyList/index'),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/company/add',
    //             name: '新增企业',
    //             component: () =>
    //                 import('@/views/district/company/companyList/report/add'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/company/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/company/preview',
    //             name: '预览',
    //             component: () =>
    //                 import(
    //                     '@/views/district/company/companyList/report/preview'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/company/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/company/information',
    //             name: '填报情况',
    //             component: () =>
    //                 import(
    //                     '@/views/district/company/companyList/report/information'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/company/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/smallProgramCompany',
    //     name: '小程序企业列表',
    //     redirect: '/smallProgramCompany/index',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/smallProgramCompany/index',
    //             name: '小程序企业列表',
    //             component: () =>
    //                 import(
    //                     '@/views/district/company/smallProgramCompany/index'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/smallProgramCompany/add',
    //             name: '新增企业',
    //             component: () =>
    //                 import('@/views/district/company/companyList/report/add'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/smallProgramCompany/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/smallProgramCompany/preview',
    //             name: '预览',
    //             component: () =>
    //                 import(
    //                     '@/views/district/company/companyList/report/preview'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/smallProgramCompany/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/smallProgramCompany/information',
    //             name: '填报情况',
    //             component: () =>
    //                 import(
    //                     '@/views/district/company/companyList/report/information'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/smallProgramCompany/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/industrialChainEnterprise',
    //     name: '产业链企业',
    //     redirect: '/industrialChainEnterprise/index',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/industrialChainEnterprise/index',
    //             name: '产业链企业',
    //             component: () =>
    //                 import(
    //                     '@/views/district/company/industrialChainEnterprise/index'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/industrialChainEnterprise/add',
    //             name: '新增企业',
    //             component: () =>
    //                 import('@/views/district/company/companyList/report/add'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/industrialChainEnterprise/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/industrialChainEnterprise/preview',
    //             name: '预览',
    //             component: () =>
    //                 import(
    //                     '@/views/district/company/companyList/report/preview'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/industrialChainEnterprise/index',
    //             },
    //             hidden: true,
    //         },
    //         {
    //             path: '/industrialChainEnterprise/information',
    //             name: '填报情况',
    //             component: () =>
    //                 import(
    //                     '@/views/district/company/companyList/report/information'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/industrialChainEnterprise/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/smallProgramUser',
    //     name: '管理小程序用户列表',
    //     component: index,
    //     redirect: '/smallProgramUser/index',
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/smallProgramUser/index',
    //             name: '管理小程序用户列表',
    //             component: () =>
    //                 import('@/views/district/user/smallProgramUser'),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/smallProgramUser/previewSmall',
    //             name: '管理小程序用户预览',
    //             component: () => import('@/views/district/user/previewSmall'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/smallProgramUser/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/trainingActivity',
    //     name: '培训活动',
    //     redirect: '/trainingActivity/index',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/trainingActivity/index',
    //             name: '培训活动',
    //             component: () =>
    //                 import('@/views/district/training/trainingActivity/index'),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/trainingActivity/add',
    //             name: '编辑培训活动',
    //             component: () =>
    //                 import(
    //                     '@/views/district/training/trainingActivity/report/add'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/trainingActivity/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/trainingCourse',
    //     name: '培训课程',
    //     redirect: '/trainingCourse/index',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/trainingCourse/index',
    //             name: '培训课程',
    //             component: () =>
    //                 import('@/views/district/training/trainingCourse/index'),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/trainingCourse/add',
    //             name: '新增培训课程',
    //             component: () =>
    //                 import(
    //                     '@/views/district/training/trainingCourse/report/add'
    //                 ),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/trainingCourse/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: '/article',
    //     name: '文章管理',
    //     redirect: '/article/index',
    //     component: index,
    //     meta: {
    //         requireAuth: true,
    //     },
    //     children: [
    //         {
    //             path: '/article/index',
    //             name: '文章管理',
    //             component: () => import('@/views/district/article/index'),
    //             meta: {
    //                 requireAuth: true,
    //             },
    //         },
    //         {
    //             path: '/article/add',
    //             name: '新增文章',
    //             component: () => import('@/views/district/article/report/add'),
    //             meta: {
    //                 requireAuth: true,
    //                 activeMenu: '/article/index',
    //             },
    //             hidden: true,
    //         },
    //     ],
    // },
]

// export const districts = constantRoutes.concat(districtRoutes)
// export const provincials = constantRoutes.concat(provincialRoutes)
// export const citys = constantRoutes.concat(cityRoutes)
// console.log('citys222', citys)
// 导出路由
const createRouter = () =>
    new Router({
        mode: 'history', // require service support
        scrollBehavior: () => ({
            y: 0,
        }),
        routes: constantRoutes,
    })
// export default new Router({
//     mode: "history", // 去掉url中的#
//     scrollBehavior: () => ({
//         y: 0,
//     }),
//     routes: citys
// })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
