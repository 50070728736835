// logout, getInfo
import { login } from '@/api/userMG'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [] // 新增
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  // 新增
  SET_ROLES: (state, roles) => {
    console.log('roles222', roles);
    state.roles = roles
  }
}

const actions = {
  // user login
  login({commit, state}, userInfo) {
    console.log('userInfo', userInfo);
    state.user = userInfo;
    localStorage.setItem("userInfo", userInfo);
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    console.log('state', state);
    return new Promise((resolve, reject) => {
      // getInfo(state.token).then(response => {
        const  data  = {
          name:'admin',
          avatar:'',
          username:'admin',
          roles:['provincial']
        }
        if (!data) {
          return reject('Verification failed, please Login again.')
        }
        // 接口返回的roles一定要是一个list，类似['admin','teacher']这种，没有的话自己处理一下也可以
        const { name, avatar,roles } = data
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }
        console.log('roles111', roles);
        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        commit('SET_ROLES', roles)
        resolve(data)
      // }).catch(error => {
      //   reject(error)
      // })
    })
  },

  // user logout
  logout({commit, state}, user) {
    console.log('user',user);
    state.user = "";
    localStorage.setItem("userInfo", "");
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken() // must remove  token  first
        resetRouter()
        commit('RESET_STATE')
    // return new Promise((resolve, reject) => {
      // logout(state.token).then(() => {
      //   // 退出登陆的时候清空一下子
      //   commit('SET_TOKEN', '')
      //   commit('SET_ROLES', [])
      //   removeToken() // must remove  token  first
      //   resetRouter()
      //   commit('RESET_STATE')
      //   resolve()
      // }).catch(error => {
      //   reject(error)
      // })
    // })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      // 重置token的时候也清空一下子
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  },

  // 改变角色 这个这个方法登录的时候不用 登录成功后切换角色后使用，后续如果需要切换角色的话，这个可以试试 commit
  changeRoles({  dispatch }, roles) {
    return new Promise( resolve => {
      //  跟换token
      // const token = role + '-token'

      // commit('SET_TOKEN', token)
      // setToken(token)

      // const { roles } = await dispatch('getInfo') // 调用文件里的getInfo方法
      resetRouter()
      roles= JSON.parse(localStorage.getItem('adminUser'))
      // 根据角色生成可访问的路由图
      const accessRoutes =  dispatch('permission/generateRoutes', roles, { root: true })

      // 动态添加可访问路由
      router.addRoutes(accessRoutes)

      // 重置已访问视图和缓存视图
      dispatch('tagsView/delAllViews', null, { root: true })

      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}