<template>
  <!-- <el-container class="index-con">
    <el-header class="index-header">
      <navcon></navcon>
    </el-header>
    <el-container class="index-con">
      <el-aside :class="showclass">
        <leftnav></leftnav>
      </el-aside>
      <el-container class="main-con">
        <el-main clss="index-main">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </el-container> -->

  <el-container class="index-con">
    <el-aside :class="showclass" class="left-nav-box" style="overflow-x: hidden;">
      <leftnav class="left-nav"></leftnav>
    </el-aside>
    <el-container class="main-con" style="overflow: hidden;">
      <el-header class="index-header" style="height:90px;padding:0;box-sizing: border-box;">
        <navcon></navcon>
      </el-header>
      <el-main clss="index-main" style="padding: 0px;">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
// 导入组件
import navcon from '../components/navcon.vue'
import leftnav from '../components/leftnav.vue'
export default {
  name: 'index',
  data() {
    return {
      showclass: 'asideshow',
      showtype: false
    }
  },
  // 注册组件
  components: {
    navcon,
    leftnav
  },
  methods: {},
  created() {
    // 监听
    this.$root.Bus.$on('toggle', value => {
      if (value) {
        this.showclass = 'asideshow'
      } else {
        setTimeout(() => {
          this.showclass = 'aside'
        }, 300)
      }
    })
  },
  beforeUpdate() {},
  // 挂载前状态(里面是操作)
  // beforeMount() {
  //   // 弹出登录成功
  //   this.$message({
  //     message: '登录成功',
  //     type: 'success'
  //   })
  // }
}
</script>
<style >
.index-con {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.aside {
  width: 64px !important;
  height: 100%;
  background-color: #334157;
  margin: 0px;
  
}
.asideshow {
  width: 240px !important;
  height: 100vh;
  background-color: #334157;
  margin: 0px;
  /* overflow: hidden; */
  
  
}
.index-header,
.index-main {
  padding: 0px;
  /* border-left: 2px solid #333; */
}
.left-nav-box{
  position: relative;
}
.left-nav{
  position: absolute; 
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
.left-nav::-webkit-scrollbar {
  width: 0;
  display: none;
}

</style>
