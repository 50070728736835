<template>
 <transition-group class="upload-file-list el-upload-list el-upload-list--text" name="el-fade-in-linear" tag="ul">
      <li :key="index" class="el-upload-list__item ele-upload-list__item-content" v-for="(file, index) in fileListSrc">
        <el-link :href="file" :underline="false" target="_blank" class="upload-image">
          <span class="el-icon-document"> {{ getFileName(file) }} </span>
        </el-link>
      </li>
    </transition-group>
</template>

<script>
export default {
  name: "FilePreview",
  props: {
    fileList: {
      type: String,
      default: ""
    }
  },
  computed: {
    fileListSrc() {
      if (!this.fileList) {
        return;
      }
      let real_src = this.fileList.split(",");
      console.log('real_src',real_src);
      return real_src;
    },
  },
  methods:{
    // 获取文件名称
    getFileName(name) {
      if (name.lastIndexOf("/") > -1) {
        return name.slice(name.lastIndexOf("/") + 1);
      } else {
        return "";
      }
    },
  }
};
</script>

<style lang="scss" scoped>

.upload-file-uploader {
  margin-bottom: 5px;
}
.upload-file-list .el-upload-list__item {
  border: 1px solid #e4e7ed;
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
}
.upload-file-list .ele-upload-list__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
}
.ele-upload-list__item-content-action .el-link {
  margin-right: 10px;
}
// .el-icon-document{
//   display: inline-block;
//   width: 100%;
  
// }
.upload-image{
  display: inline-block;
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
