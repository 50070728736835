// logout, getInfo
// import { login } from '@/api/userMG'
// import { getToken, setToken, removeToken } from '@/utils/auth'
// import router, { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    content:JSON.parse(sessionStorage.getItem("content")) || {}
  }
}

const state = getDefaultState()

const mutations = {
  SET_CONTENT: (state, content) => {
    console.log('content', content);
    state.content = content
    sessionStorage.setItem("content", JSON.stringify(content))
  },
  
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}