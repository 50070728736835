<template>
    <div class="component-upload-image">
        <el-upload
            :action="uploadVideoUrl"
            :on-success="handleUploadSuccess"
            :before-upload="handleBeforeUpload"
            :limit="limit"
            :on-error="handleUploadError"
            :on-exceed="handleExceed"
            ref="videoUpload"
            :on-remove="handleDelete"
            :show-file-list="true"
            :headers="headers"
            :file-list="fileList"
            :on-preview="handlePictureCardPreview"
            :class="{ hide: this.fileList.length >= this.limit }"
        >
            <el-button size="mini" type="primary">上传视频</el-button>
            <!-- <el-row type="flex" :gutter="24">
                <el-col :span="42" v-for="(item, index) in fileList" :key="index">
                  <video
                    :src="item.url"
                    class="video-box"
                    controls="controls"
                    >
                      您的浏览器不支持视频播放
                  </video>
                </el-col>
                <el-col :span="12">
                  <div class="push-box">
                    <i class="el-icon-plus"></i>
                  </div>
                   
                   </el-col>
            </el-row> -->
        </el-upload>

        <!-- 上传提示 -->
        <div class="el-upload__tip" slot="tip" v-if="showTip">
            请上传
            <template v-if="fileSize">
                大小不超过 <b style="color: #f56c6c">{{ fileSizeFun(fileSize) }}</b>
            </template>
            <template v-if="fileType">
                格式为 <b style="color: #f56c6c">{{ fileType.join('/') }}</b>
            </template>
            的文件
        </div>

        <el-dialog
            :visible.sync="dialogVisible"
            title="预览"
            width="800"
            append-to-body
        >
            <video
                :src="dialogVideoUrl"
                controls
                style="display: block; max-width: 100%; margin: 0 auto"
            ></video>
        </el-dialog>
    </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { getFileUrl } from '@/api/file'

export default {
    props: {
        value: [String, Object, Array],
        // 视频数量限制
        limit: {
            type: Number,
            default: 5,
        },
        // 大小限制(MB)
        fileSize: {
            type: Number,
            default: 5,
        },
        // 文件类型, 例如['png', 'jpg', 'jpeg']
        fileType: {
            type: Array,
            default: () => ['mp4', 'mov', 'wmv'],
        },
        // 是否显示提示
        isShowTip: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            number: 0,
            uploadList: [],
            dialogVideoUrl: '',
            dialogVisible: false,
            hideUpload: false,
            uploadVideoUrl: process.env.VUE_APP_BASE_API + '/api/file/store', // 上传的视频服务器地址
            headers: {
                Authorization: getToken(),
            },
            fileList: [],
        }
    },
    watch: {
        value: {
            async handler(val) {
                if (val) {
                    // 然后将数组转为对象数组
                    this.fileList = await this.getFileUrlFun(val)
                    return this.fileList
                } else {
                    this.fileList = []
                    return []
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        // 是否显示提示
        showTip() {
            return this.isShowTip && (this.fileType || this.fileSize)
        },
    },
    methods: {
        fileSizeFun(value){
            var fileValue = ''
            console.log('value', parseInt(value / 1024));
            fileValue = parseInt(value / 1024) > 0 ? parseFloat((value / 1024).toFixed(2)) +'G':value+'MB'
            return fileValue
        },
        async getFileUrlFun(filesId) {
            var params = {
                ids: filesId,
            }
            var res = await getFileUrl(params)
            if (res.code === 200) {
                const resData = res.data
                const urlArr = []
                resData.map((val, inx) => {
                    urlArr.push({
                        name: val.oldName,
                        url: val.url,
                        id: val.id,
                        uid: -inx,
                        status: 'done',
                    })
                })
                return urlArr
            } else {
                return []
            }
        },
        // 上传前loading加载
        handleBeforeUpload(file) {
            console.log('file', file)
            let isVideo = false
            if (this.fileType.length) {
                let fileExtension = ''
                if (file.name.lastIndexOf('.') > -1) {
                    fileExtension = file.name.slice(
                        file.name.lastIndexOf('.') + 1
                    )
                }

                isVideo = this.fileType.some((type) => {
                    if (file.type.indexOf(type) > -1) return true
                    if (fileExtension && fileExtension.indexOf(type) > -1)
                        return true
                    return false
                })
            } else {
                isVideo = file.type.indexOf('video') > -1
            }

            if (!isVideo) {
                this.$modal.msgError(
                    `文件格式不正确, 请上传${this.fileType.join(
                        '/'
                    )}视频格式文件!`
                )
                return false
            }

            if (this.fileSize) {
                const isLt = file.size / 1024 / 1024 < this.fileSize
                if (!isLt) {
                    this.$modal.msgError(
                        `上传视频大小不能超过 ${this.fileSize} MB!`
                    )
                    return false
                }
            }
            this.$modal.loading('正在上传视频，请稍候...')
            this.number++
        },
        // 文件个数超出
        handleExceed() {
            this.$modal.msgError(`上传文件数量不能超过 ${this.limit} 个!`)
        },
        // 上传成功回调
        handleUploadSuccess(res, file) {
            if (res.code === 200) {
                this.uploadList.push({ name: res.data.oldName,id:res.data.id, url: res.data.url })
                this.uploadedSuccessfully()
            } else {
                this.number--
                this.$modal.closeLoading()
                this.$modal.msgError(res.msg)
                this.$refs.videoUpload.handleRemove(file)
                this.uploadedSuccessfully()
            }
        },
        // 删除图片
        handleDelete(file) {
            const findex = this.fileList.map((f) => f.name).indexOf(file.name)
            if (findex > -1) {
                this.fileList.splice(findex, 1)
                this.$emit('input', this.listToString(this.fileList))
            }
        },
        // 上传失败
        handleUploadError() {
            this.$modal.msgError('上传视频失败，请重试')
            this.$modal.closeLoading()
        },
        // 上传结束处理
        uploadedSuccessfully() {
            if (this.number > 0 && this.uploadList.length === this.number) {
                this.fileList = this.fileList.concat(this.uploadList)
                this.uploadList = []
                this.number = 0
                this.$emit('input', this.listToString(this.fileList))
                this.$modal.closeLoading()
            }
        },
        // 预览
        handlePictureCardPreview(file) {
            this.dialogVideoUrl = file.url
            this.dialogVisible = true
        },
        // 对象转成指定字符串分隔
        listToString(list, separator) {
            let strs = ''
            separator = separator || ','
            for (let i in list) {
                if (list[i].id) {
                    strs += list[i].id.replace(this.baseUrl, '') + separator
                }
            }
            return strs != '' ? strs.substr(0, strs.length - 1) : ''
        },
    },
}
</script>
<style scoped lang="scss">
// .el-upload--picture-card 控制加号部分
::v-deep.hide .el-upload--picture-card {
    display: none;
}
// 去掉动画效果
::v-deep .el-list-enter-active,
::v-deep .el-list-leave-active {
    transition: all 0s;
}

::v-deep .el-list-enter,
.el-list-leave-active {
    opacity: 0;
    transform: translateY(0);
}
.video-box{
  width: 148px;
  height: 148px;
}
.push-box{
    width: 148px;
    height: 148px;
    background: #fbfdff;
    border-radius: 6px;
    border: 1px dashed #c0ccda;
    display: flex;
    justify-content: center;
    align-items: center;
    i{
        font-size: 28px;
        color: #8c939d;
    }
}
</style>
