/** * 头部菜单 */
<template>
    <div>
            <el-row style="background:#fff" class="el-menu-demo" type="flex" :gutter="24" justify="space-between" align="middle" >
                <el-col :span="2">
                    <el-button class="buttonimg"  @click="toggle(collapsed)">
                        <i :class="collapsed?'el-icon-s-fold':'el-icon-s-unfold'" style="font-size:28px"></i>
                    </el-button>
                </el-col>
                <el-col :span="8">
                    <div  style=" display: flex; justify-content:flex-end;padding:0 20px">
                        <div v-if="!user.cityCode && !user.countyCode" style="display: flex; align-items: center;justify-content:flex-end;">
                            <el-button @click="analysisResultClick" size="medium" round>催报工作台</el-button>
                            <el-button round size="medium" @click="dataLargeClick">数据大屏</el-button>
                        </div>
                        <div style="display: flex; align-items: center;margin-left:40px;">
                            <el-dropdown @command="handleCommand" style="width: 80px;display: block;">
                                <span class="el-dropdown-link">
                                    {{user.nameReal}}<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu  slot="dropdown">
                                    <el-dropdown-item command="c">个人中心</el-dropdown-item>
                                    <el-dropdown-item command="t">退出</el-dropdown-item>
                                </el-dropdown-menu>
                                </el-dropdown>
                        </div>
                    </div>
                </el-col>
            </el-row>
        <div style="position: relative; z-index: 999">
            <topNav></topNav>
        </div>
    </div>
</template>
<script>
import{ removeToken} from'../utils/auth'
import topNav from './topNav.vue'
import {
    getNewIdApi,
} from '@/api/analysisResultApi'
export default {
    components: {
        topNav,
    },
    name: 'navcon',
    data() {
        return {
            collapsed: true,
            imgshow: require('../assets/images/show.png'),
            imgsq: require('../assets/images/sq.png'),
            user: {},
        }
    },
    // 创建完毕状态(里面是操作)
    created() {
        this.user = JSON.parse(localStorage.getItem('adminUser'))
    },
    methods: {
        // 退出登录
        exit() {
            this.$confirm('退出登录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    setTimeout(() => {
                        removeToken()
                        this.$router.push({
                            path: '/login',
                        })
                    }, 1000)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消',
                    })
                })
        },
        handleCommand(command){
            if(command === 'c'){
                this.content()
            }else{
                this.exit()
            }
        },
        // 个人中心
        content() {
            this.$router.push({
                path: '/profile',
            })
        },
        // 切换显示
        toggle(showtype) {
            console.log('showtype', showtype)
            this.collapsed = !showtype
            this.$root.Bus.$emit('toggle', this.collapsed)
        },
        // 催报工作台
        async analysisResultClick() {
            // 先判断当前是否有正在填报的问卷
            // 如果有就跳转
            var id = await this.getNewIdFun()
            if(id){
                window.open('/analysisResult')
            }else{
                this.$message({
                  message: '当前没有正在催报的问卷',
                  type: 'error',
              })
            }
            
        },
        async getNewIdFun() {
            var res = await getNewIdApi()
            return res.code === 200 ? res.data : null
        },
        // 数据大屏
        dataLargeClick() {
            var url =
                'https://datav.aliyuncs.com/share/4fcf045f3f8d03dbcf4e696cf669fe90'
            window.open(url, '_blank')
        },
    },
}
</script>
<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    border: none;
}

.submenu {
    /* float: right !important; */
}

.buttonimg {
    height: 60px;
    background-color: transparent !important;
    border: none;
}

.showimg {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 17px;
    left: 17px;
}

.showimg:active {
    border: none;
}

.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
    border-bottom: 0px;
}
::v-deep .el-header{
    padding: 0;
}
.el-menu-demo{
    border-bottom: 1px solid #e6e6e6;
}
</style>
