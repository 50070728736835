/**
 * @module wLayoutMenu 一键排版的按钮
 * @author cy
 * @date 2021/03/29
 * @description 自定义一个一键排版的功能
 */
// 引入 wangEditor
import E from "wangeditor";
const {
  $,
  BtnMenu
} = E;

// 设置默认样式
const initPStyle =
  "text-indent: 2em; margin: 0; font-size: 1em;line-height:2; font-weight: normal; color: #000000";
const imgStyle = "line-height:2; text-align: center";

const wLayoutMenu = function () {
  const typeSetting = class extends BtnMenu {
    constructor(editor) {
      // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
      const $elem = $(
        '<div class="w-e-menu" data-title="一键排版"><i class="el-icon-s-help"></i></div>'
      );
      super($elem, editor);
    }
    clickHandler() {
      // 获取富文本编辑区的DOM
      const childDoms = this.editor.$textElem.elems[0].childNodes;
      if (childDoms.length > 0) {
        childDoms.forEach((item) => {
          // 富文本编辑的内容默认外层都是由p标签包容，所以直接在p标签上进行设置默认排版的样式
          if (item.nodeName === "P") {
            item.style.cssText = initPStyle;
          }
          if (item.childNodes.length > 0) {
            // 遍历找到子元素中存在img标签的内容进行设置默认样式
            item.childNodes.forEach((child1) => {
              if (child1.nodeName === "IMG") {
                item.style.cssText = imgStyle;
              }
              child1.childNodes.forEach((child2) => {
                if (child2.nodeName === "IMG") {
                  item.style.cssText = imgStyle;
                }
                child2.childNodes.forEach((child3) => {
                  if (child3.nodeName === "IMG") {
                    item.style.cssText = imgStyle;
                  }
                  child3.childNodes.forEach((child4) => {
                    if (child4.nodeName === "IMG") {
                      item.style.cssText = imgStyle;
                    }
                  });
                });
              });
            });
          }
        });
      }
    }
    // 菜单是否被激活（如果不需要，这个函数可以空着）
    tryChangeActive() {

		}
  };
  // 注册菜单
  E.registerMenu('typeSetting', typeSetting)

  const clear = class extends BtnMenu {
    constructor(editor) {
      // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
      const $elem = $(
        '<div class="w-e-menu" data-title="清空"><i class="el-icon-delete-solid"></i></div>'
      );
      super($elem, editor);
    }
    clickHandler() {
      this.editor.txt.clear();
    }
    tryChangeActive() {
			
		}
  };
  // 注册菜单
  E.registerMenu('clear', clear)
}
export default wLayoutMenu
