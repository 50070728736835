/** * 左边菜单 */
<template>
    <div>
        <el-menu
            collapse-transition
            router
            :default-active="activeMenu"
            unique-opened
            class="el-menu-vertical-demo"
            background-color="#334157"
            text-color="#fff"
            active-text-color="#FFF"
            @open="handleOpen"
            @close="handleClose"
            :collapse="collapsed"
        >
            <div class="logobox1">
                <img class="logoimg" :src="logo1" alt="" />
                <span v-show="!collapsed">山东省商务厅
                    <br/>
                    外贸运行监测系统</span>
            </div>
            <div
                v-for="menu in allmenu"
                :index="menu.menuname"
                :key="menu.menuid"
            >
                <el-submenu :index="menu.menuname" v-if="menu.menus != null">
                    <template slot="title">
                        <i :class="`iconfont ${menu.icon} icon-box`"></i>
                        <span slot="title" v-show="!collapsed">{{
                            menu.menuname
                        }}</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item
                            v-for="chmenu in menu.menus"
                            :index="'/' + chmenu.url"
                            :key="chmenu.menuid"
                        >
                            <!-- <i class="iconfont" :class="chmenu.icon"></i> -->
                            <span>{{ chmenu.menuname }}</span>
                        </el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                <el-menu-item v-else :index="'/' + menu.url">
                    <i :class="`iconfont ${menu.icon} icon-box`"></i>
                    <span slot="title">{{ menu.menuname }}</span>
                </el-menu-item>
            </div>
        </el-menu>
    </div>
</template>
<script>
// import { menu } from '../api/userMG'
export default {
    name: 'leftnav',
    data() {
        return {
            collapsed: false,
            allmenu: [],
            logo: require('../assets/images/menuLogo.png'),
            logo1: require('../assets/images/MenuLogoSmall.png'),
        }
    },
    // 创建完毕状态(里面是操作)
    created() {
        // 获取图形验证码
        let res = {
            success: true,
            data: [
                {
                    menuid: 1,
                    icon: 'icon-kongzhitai',
                    menuname: '控制台',
                    hasThird: null,
                    url: '',
                    menus: null,
                },
                {
                    menuid: 33,
                    icon: 'icon-wenjuan',
                    menuname: '问卷管理',
                    hasThird: null,
                    url: null,
                    menus: [
                        {
                            menuid: 34,
                            icon: 'icon-order-manage',
                            menuname: '模板列表',
                            hasThird: 'N',
                            url: 'questionnaire/formboard',
                            menus: null,
                        },
                        {
                            menuid: 35,
                            icon: 'icon-order-manage',
                            menuname: '问卷列表',
                            hasThird: 'N',
                            url: 'list/index',
                            menus: null,
                        },
                        {
                            menuid: 36,
                            icon: 'icon-order-manage',
                            menuname: '答案列表',
                            hasThird: 'N',
                            url: 'answerlist/index',
                            menus: null,
                        },
                        {
                            menuid: 37,
                            icon: 'icon-order-manage',
                            menuname: '用户平台信息',
                            hasThird: 'N',
                            url: 'userPlatformInformation',
                            menus: null,
                        },
                        {
                            menuid: 38,
                            icon: 'icon-order-manage',
                            menuname: '商务部推送日志',
                            hasThird: 'N',
                            url: 'businessPushLog/index',
                            menus: null,
                        },
                    ],
                },
                {
                    menuid: 71,
                    icon: 'icon-qiye',
                    menuname: '企业管理',
                    hasThird: null,
                    url: null,
                    menus: [
                        {
                            menuid: 72,
                            icon: 'icon-cus-manage',
                            menuname: '企业联系人',
                            hasThird: 'N',
                            url: 'company/user',
                            menus: null,
                        },
                        {
                            menuid: 75,
                            icon: 'icon-cus-manage',
                            menuname: '企业组列表',
                            hasThird: 'N',
                            url: 'company/group',
                            menus: null,
                        },
                        {
                            menuid: 174,
                            icon: 'icon-cms-manage',
                            menuname: '企业列表',
                            hasThird: 'N',
                            url: 'company/index',
                            menus: null,
                        },
                        {
                            menuid: 73,
                            icon: 'icon-news-manage',
                            menuname: '小程序企业列表',
                            hasThird: 'N',
                            url: 'smallProgramCompany/index',
                            menus: null,
                        },
                        {
                            menuid: 74,
                            icon: 'icon-cs-manage',
                            menuname: '产业链企业',
                            hasThird: 'N',
                            url: 'industrialChainEnterprise/index',
                            menus: null,
                        },
                    ],
                },
                {
                    menuid: 128,
                    icon: 'icon-yonghuguanli_huaban',
                    menuname: '用户管理',
                    hasThird: null,
                    url: null,
                    menus: [
                        {
                            menuid: 129,
                            icon: 'icon-provider-manage',
                            menuname: '管理用户列表',
                            hasThird: 'N',
                            url: 'userList/index',
                            menus: null,
                        },
                        {
                            menuid: 175,
                            icon: 'icon-provider-manage',
                            menuname: '小程序用户列表',
                            hasThird: 'N',
                            url: 'smallProgramUser/index',
                            menus: null,
                        },
                    ],
                },
                {
                    menuid: 32,
                    icon: 'icon-diqu',
                    menuname: '地区管理',
                    hasThird: null,
                    url: 'district/index',
                    menus: null,
                },
                // {
                //     menuid: 31,
                //     icon: 'icon-baogao',
                //     menuname: '报告管理',
                //     hasThird: null,
                //     url: 'reportManagement/index',
                //     menus: null,
                // },
                  {
                    menuid: 131,
                    icon: 'icon-baogao',
                    menuname: '报告管理',
                    hasThird: null,
                    url: null,
                    menus: [
                        {
                            menuid: 132,
                            icon: '',
                            menuname: '省级报告',
                            hasThird: 'N',
                            url: 'reportManagement/index',
                            menus: null,
                        },
                        {
                            menuid: 133,
                            icon: '',
                            menuname: '地市报告',
                            hasThird: 'N',
                            url: 'reportManagement/cityIndex',
                            menus: null,
                        },
                    ],
                },
                {
                    menuid: 30,
                    icon: 'icon-bianjiwenzhang_huaban',
                    menuname: '文章管理',
                    hasThird: null,
                    url: 'article/index',
                    menus: null,
                },
                {
                    menuid: 40,
                    icon: 'icon-dapingzhanshi',
                    menuname: '大屏管理',
                    hasThird: null,
                    url: 'dataScreenManagement/index',
                    menus: null,
                },
                {
                    menuid: 150,
                    icon: 'icon-peixun',
                    menuname: '培训管理',
                    hasThird: null,
                    url: null,
                    menus: [
                        {
                            menuid: 159,
                            icon: 'icon-provider-manage',
                            menuname: '培训活动',
                            hasThird: 'N',
                            url: 'trainingActivity/index',
                            menus: null,
                        },
                        {
                            menuid: 160,
                            icon: 'icon-provider-manage',
                            menuname: '培训课程',
                            hasThird: 'N',
                            url: 'trainingCourse/index',
                            menus: null,
                        },
                    ],
                },
            ],
            msg: 'success',
        }
        let cityRes = [
            {
                menuid: 1,
                icon: 'icon-kongzhitai',
                menuname: '控制台',
                hasThird: null,
                url: '',
                menus: null,
            },
            // {
            //     menuid: 30,
            //     icon: 'icon-baogao',
            //     menuname: '报告管理',
            //     hasThird: null, 
            //     url: 'reportManagement/index',
            //     menus: null,
            // },
						{
							menuid: 131,
							icon: 'icon-baogao',
							menuname: '报告管理',
							hasThird: null,
							url: null,
							menus: [
									{
											menuid: 132,
											icon: '',
											menuname: '省级报告',
											hasThird: 'N',
											url: 'reportManagement/index',
											menus: null,
									},
									{
											menuid: 133,
											icon: '',
											menuname: '地市报告',
											hasThird: 'N',
											url: 'reportManagement/cityIndex',
											menus: null,
									},
							],
					},
        ]
        let countyRes = [
            {
                menuid: 1,
                icon: 'icon-kongzhitai',
                menuname: '控制台',
                hasThird: null,
                url: '',
                menus: null,
            },
            // {
            //     menuid: 30,
            //     icon: 'icon-baogao',
            //     menuname: '报告管理',
            //     hasThird: null, 
            //     url: 'reportManagement/index',
            //     menus: null,
            // },
						
						{
							menuid: 131,
							icon: 'icon-baogao',
							menuname: '报告管理',
							hasThird: null,
							url: null,
							menus: [
									{
											menuid: 132,
											icon: '',
											menuname: '省级报告',
											hasThird: 'N',
											url: 'reportManagement/index',
											menus: null,
									},
									{
											menuid: 133,
											icon: '',
											menuname: '地市报告',
											hasThird: 'N',
											url: 'reportManagement/cityIndex',
											menus: null,
									},
							],
					},
        ]
        var adminUser = JSON.parse(localStorage.getItem('adminUser'))
        if(adminUser.cityCode && !adminUser.countyCode){
            this.allmenu = cityRes
        }else if(adminUser.cityCode && adminUser.countyCode){
            this.allmenu = countyRes
        }else{
            this.allmenu = res.data
        }
        
        // menu(localStorage.getItem('logintoken'))
        //   .then(res => {
        //     console.log(JSON.stringify(res))
        //     if (res.success) {
        //       this.allmenu = res.data
        //     } else {
        //       this.$message.error(res.msg)
        //       return false
        //     }
        //   })
        //   .catch(err => {
        //     this.$message.error('菜单加载失败，请稍后再试！')
        //   })
        // 监听
        this.$root.Bus.$on('toggle', (value) => {
            this.collapsed = !value
        })
    },
    computed: {
        activeMenu() {
            const route = this.$route
            const { meta, path } = route
            // if set path, the sidebar will highlight the path you set
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            return path
        },
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath)
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath)
        },
    },
}
</script>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 240px;
    min-height: 400px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
    border: none;
    text-align: left;
}
.el-menu-item-group__title {
    padding: 0px;
}
.el-menu-bg {
    background-color: #1f2d3d !important;
}
.el-menu {
    border: none;
    /* position: absolute;

 left: 0;

 top: 0;

 right: -17px;

 bottom: 0;

 overflow-x: hidden;

 overflow-y: scroll; */
}
.logobox {
    height: 80px;
    line-height: 80px;
    color: #9d9d9d;
    font-size: 20px;
    text-align: center;
    /* padding: 20px 0px; */
}
.logobox1 {
    height: 40px;
    /* line-height: 40px; */
    color: #fff;
    /* font-size: 20px; */
    text-align: center;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logoimg {
    /* height: 40px; */
    height: 100%;
}
::v-deep .el-menu--collapse .el-submenu__title > span {
    display: none !important;
}
/*隐藏 > */
::v-deep .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
    display: none !important;
}
/* .el-menu-item:hover{
    outline: 0 !important;
    color: #fff !important;
    background-color: rgba(46 149 251,0.8)!important;
} */
.el-menu-item.is-active {
    outline: 0 !important;
    color: #fff !important;
    background-color: #2e95fb !important;
}
.icon-box {
    font-size: 15px;
    color: #fff;
    font-weight: bold;
    margin-right: 10px;
}
</style>
