import request from '@/utils/request.js'

// 催报工作台 --- 获取最新问卷id
export function getNewIdApi(data) {
  return request({
    url: '/doftec/admin-survey/getNewId',
    method: 'post',
    data: data
  })
}

// 催报工作台 --- 小时段统计
export function hourSegmentStatisticsApi(id, params) {
  return request({
    url: '/doftec/admin-survey/count/hour/'+id,
    method: 'get',
    params: params
  })
}

// 催报工作台 --- 日期段统计
export function dateSegmentStatisticsApi(id, params) {
  return request({
    url: '/doftec/admin-survey/count/date/'+id,
    method: 'get',
    params: params
  })
}

// 催报工作台 --- 企业名单（包含填报率）
export function tableCompanyApi(id,type, params) {
  return request({
    url: '/doftec/admin-survey/company/'+id+'/'+type+'/list',
    method: 'get',
    params: params
  })
}

// 催报工作台 --- 根据问题编码和问卷id下载答案
export function downloadAnswerNewApi(params) {
  return request({
    url: '/doftec/admin-survey/downloadAnswerNew',
    method: 'get',
    params: params
  })
}

// 催报工作台 --- 根据问题编码和问卷id 上传企业得分
export function importAnswerNewApi(data) {
  return request({
    url: '/doftec/admin-survey/importData',
    method: 'post',
    data: data
  })
}

// 催报工作台 --- 根据企业编码查询所有填报问卷
export function getWjListByUsccApi(data) {
  return request({
    url: '/doftec/admin-survey/getWjListByUscc/list',
    method: 'post',
    data: data
  })
}

// 催报工作台 --- 带有比分的问卷数据
export function surveyScoreApi(id, params) {
  return request({
    url: '/doftec/admin-survey/survey/get/score/'+id,
    method: 'get',
    params: params
  })
}

// 催报工作台-问卷企业组统计
export function surveyCompanyStatisticsApi(id, params) {
  return request({
    url: '/doftec/admin-survey/group',
    method: 'get',
    params: params
  })
}

// 催报工作台-问卷地区统计
export function surveyCityStatisticsApi(id, params) {
  return request({
    url: '/doftec/admin-survey/city/'+id,
    method: 'get',
    params: params
  })
}

// 催报工作台-问卷人员职位统计
export function surveyJobStatisticsApi(id, params) {
  return request({
    url: '/doftec/admin-survey/job/'+id,
    method: 'get',
    params: params
  })
}

// 催报工作台 --- 双稳平台推送信息管理
export function surveyBistablePlatformApi(id, params) {
  return request({
    url: '/doftec/admin-survey/list/push/answer/'+id,
    method: 'get',
    params: params
  })
}

// 催报工作台 --- 下载所有问卷数据（code =0） 下载所有问卷数据（编码code=1）
export function surveyDownloadApi(id, params) {
  return request({
    url: '/doftec/admin-survey/download/answer/'+id,
    method: 'get',
    params: params
  })
}
// 催报工作台 --- 下载企业及联系方式
export function surveyDownloadCompanyTelApi(id, params) {
  return request({
    url: '/doftec/admin-survey/download/company/'+id,
    method: 'get',
    params: params
  })
}

// 催报工作台 --- 下载未填报企业及联系方式

export function surveyDownloadNotCompanyTelApi(id, params) {
  return request({
    url: 'doftec/admin-survey/download/company/not/'+id,
    method: 'get',
    params: params
  })
}
