import Vue from 'vue';
import Vuex from 'vuex';
import permission from './modules/permission'
import user from './modules/user'
import getters from './getters'
import app from "./modules/app"
import tagsView from "./modules/tagsView"
import xml from "./modules/xml"

Vue.use(Vuex);
// 登录验证
export default new Vuex.Store({
    namespaced:true,
    state: {
        // user: false,
        route:[],
        isExport:false,
        isDownload:false,
        code:200
    },
    mutations:{
        SET_isExport(state,value){
            state.isExport = value
        },
        SET_isDownload(state,value){
            state.isDownload = value
        },
        SET_CODE(state, value){
            state.code = value
        }
    },
    modules: {
        xml,
        permission,
        user,
        app,
        tagsView,
        // 登录
        login(state, user) {
            console.log('user',user);
            state.user = user;
            localStorage.setItem("userInfo", user);
        },
        // // 退出
        logout() {
        console.log('退出登录');
        },
    },
    getters
})