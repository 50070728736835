import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import'./assets/styles/reast.scss'
import './assets/icon/iconfont.css'
import './permission'
import './utils/filter'
import { download,download2 } from './utils/request';
var wx = require('weixin-js-sdk')

// json 编辑器
import CodeEditor from 'bin-code-editor';
Vue.use(CodeEditor);

Vue.prototype.$wx = wx;

// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 引入富文本插件
import Editor from './components/Editor/index'
// 引入图片上传插件
import ImageUpload from './components/ImageUpload/index'
// 引入图片预览插件
import ImagePreview from './components/ImagePreview/index'
// 引入文件上传插件
import FileUpload from './components/FileUpload/index'
// 引入文件预览插件
import FilePreview from './components/FilePreview/index'
// 引入视频上传插件
import videoUpload from './components/videoUpload/index'
// 引入文件预览插件
import videoPreview from './components/videoPreview/index'
// import "@/mock";
// 问卷共用css
import './assets/styles/wenjuan.css'

// 弹窗
// import modal from './plugins/modal.js'
// 
import plugins from './plugins/index' // plugins

// 使用element UI
Vue.use(ElementUI);
Vue.use(plugins)

import axios from 'axios';
Vue.prototype.$axios = axios;
Vue.prototype.download = download
Vue.prototype.download2 = download2
Vue.prototype.enterpriseLabel = [{
  label:'外贸样本企业',
  value:'YB'
},{
  label:'商务部外贸直报企业',
  value:'ZB'
},{
  label:'其他',
  value:'FYB'
}]
 // 模态框对象
//  Vue.prototype.$modal = modal

Vue.config.productionTip = false

Vue.component('Editor', Editor)
Vue.component('ImageUpload', ImageUpload)
Vue.component('ImagePreview', ImagePreview)
Vue.component('FileUpload', FileUpload)
Vue.component('FilePreview', FilePreview)
Vue.component('videoUpload', videoUpload)
Vue.component('videoPreview', videoPreview)

new Vue({
  router,
  store,
  data: {
    // 空的实例放到根组件下，所有的子组件都能调用
    Bus: new Vue()
  },
  render: h => h(App)
}).$mount('#app')