import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})
Vue.filter('dayTimejs', function (dataStr, pattern = 'YYYY-MM-DD HH:MM:SS') {
  return moment(dataStr).format(pattern)
})

Vue.filter('dateformat', function (dataStr, pattern = 'YYYY-MM-DD') {
  return moment(dataStr).format(pattern)
})

Vue.filter('stateToName', function (status) {
	const statusMap = {}
	statusMap[0] = '暂存'
	statusMap[5] = '正在审核'
	statusMap[10] = '通过审核'
	statusMap[-5] = '驳回'
	statusMap[-10] = '彻底驳回'
	return statusMap[status]
})

Vue.filter('isYes', function (status) {
	const statusMap = {}
	statusMap[0] = '否'
	statusMap[1] = '是'
	return statusMap[status]
})

Vue.filter('effective', function (status) {
	const statusMap = {}
	statusMap[-1] = '无效'
	statusMap[0] = '有效'
	return statusMap[status]
})

Vue.filter('isNo', function (status) {
	const statusMap = {}
	statusMap[1] = '否'
	statusMap[0] = '是'
	return statusMap[status]
})

Vue.filter('companyType', function (status) {
	const statusMap = {}
	statusMap['YB'] = '外贸样本企业'
	statusMap['FYB'] = '其他'
	statusMap['ZB'] = '商务部外贸直报企业'
	return status?statusMap[status]:'外贸样本企业'
})


Vue.filter('companyTypeTag', function (status) {
	const statusMap = {}
	statusMap['YB'] = ''
	statusMap[''] = ''
	statusMap['FYB'] = 'info'
	statusMap['ZB'] = 'danger'
	return statusMap[status]
})

Vue.filter('groupType', function (status) {
	const statusMap = {}
	statusMap['WM'] = '外贸'
	statusMap['WZ'] = '外资'
	statusMap['WJ'] = '外经'
	return statusMap[status]
})