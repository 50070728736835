<template>
	<div :class="prefixCls" style="width:100%">
		<div ref="editor" class="editor-wrapper" id="editor"></div>
	</div>
</template>

<script>
import { commonUpload } from '@/api/file.js'
import WEditor from 'wangeditor'
import AlertMenu from '@/utils/AlertMenu'
import wLayoutMenu from '@/utils/wLayoutMenu'

export default {
	name: 'WangEditor',
	props: {
		prefixCls: {
			type: String,
			default: 'ant-editor-wang'
		},
		// eslint-disable-next-line
		value: {
			type: String
		},
		// 上传文件大小限制(MB)
		imgSize: {
			type: Number,
			default: 10,
		},
		videoSize: {
			type: Number,
			default: 1024,
		},
		/* 高度 */
		height: {
			type: Number,
			default: 300,
		},
	},
	data() {
		return {
			editor: null,
			editorContent: null
		}
	},
	async mounted() {
		AlertMenu()
		wLayoutMenu()
		this.initEditor()
		this.$nextTick(() => {
			//有值直接用，没值监听到再用
			if (this.value) {
				this.editor.txt.html(this.value)
			} else {
				const unwatch = this.$watch("value", function (val) {
					this.editor.txt.html(val)
					// 处理业务逻辑
					// 之后调用unwatch, 取消监听
					unwatch();
				});

			}
		})
	},
	methods: {
		initEditor() {
			this.editor = new WEditor(this.$refs.editor)
			this.editor.config.excludeMenus = [
        'video'
    ]
			this.editor.config.zIndex = 1
			this.editor.config.height = this.height
			this.editor.config.uploadVideoMaxSize = 1 * this.videoSize * 1024
			this.editor.config.uploadImgMaxSize = 1 * this.imgSize * 1024 * 1024
			this.editor.config.onchange = (html) => {
				//this.editorContent = html
				this.$emit("input", html);
				//this.$emit('change', this.editorContent)
			}
			this.editor.config.uploadImgShowBase64 = true
			// 自定义文件上传方法
			this.editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
				const formData = new window.FormData()
				for (var i = 0; i < resultFiles.length; i++) {
					formData.append('file', resultFiles[i])
				}
				const res = await commonUpload(formData)
				insertImgFn(res.data.url)
			}
			// this.editor.config.customUploadVideo = async function (resultFiles, insertVideoFn) {
			// 	const formData = new window.FormData()
			// 	for (var i = 0; i < resultFiles.length; i++) {
			// 		formData.append('file', resultFiles[i])
			// 	}
			// 	const resp = await commonUpload(formData)
			// 	insertVideoFn(resp.data.url)
			// }
			this.editor.create()
		}
	}
}
</script>

<style lang="scss" scoped>
.ant-editor-wang {
	.editor-wrapper {
		text-align: left;
	}
}
</style>
