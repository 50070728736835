import request from '@/utils/request'
// import { req } from './axiosFun';

// 上传图片
export function commonUpload(data) {
  return request({
    url: '/api/file/store',
    method: 'post',
    data: data
  })
}
// export const commonUpload = (params) => { return req("post", "/upload/report", params) };

// 根据文件id返回文件地址
export function getFileUrl(params) {
  return request({
    url: '/api/file/files/query',
    method: 'GET',
    params: params
  })
}

