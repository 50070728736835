import router from './router'
import store from './store'
import { Message } from 'element-ui'
// import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css' // progress bar style
import { getToken, removeToken } from '@/utils/auth' // get token from cookie
// import getPageTitle from '@/utils/get-page-title'

// NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = [
    '/login',
    '/mp/view/action',
    '/mp/view/survey/view',
    '/mp/view/survey/list',
] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
    // start progress bar
    // NProgress.start()

    // set page title
    // document.title = getPageTitle(to.meta.title)

    // determine whether the user has logged in
    const hasToken = getToken()
    if (hasToken) {
        if (to.path === '/login') {
            // if is logged in, redirect to the home page
            next({ path: '/' })
            // NProgress.done()
        } else {
            // 修改
            // const hasGetUserInfo = store.getters.name
            const hasRoles =
                store.getters.permission_routes &&
                store.getters.permission_routes.length > 0
            if (hasRoles) {
                next()
            } else {
                try {
                    // 第一步
                    const roles = JSON.parse(localStorage.getItem('adminUser'))
                    // 获取通过权限的路由  第二步
                    const accessRoutes = await store.dispatch(
                        'permission/generateRoutes',
                        roles
                    )
                    // 更新路由 第三步
                    router.options.routes = store.getters.permission_routes

                    // 动态添加可访问路由
                    router.addRoutes(accessRoutes)

                    next({ ...to, replace: true })
                } catch (error) {
                    // remove token and go to login page to re-login
                    removeToken() // must remove  token  first
                    await store.dispatch('user/resetToken')
                    Message.error(error || 'Has Error')
                    next(`/login?redirect=${to.path}`)
                    // NProgress.done()
                }
            }
        }
    } else {
        /* has no token*/

        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            next()
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            next(`/login?redirect=${to.path}`)
            // NProgress.done()
        }
    }

    // 如果当前的路由包含arr数组中定义的路由  并且去的路由也是arr数组中的路由 清空搜索条件
    let arrList = [
        '/questionnaire/formboard',
        '/list/index',
        '/answerlist/index',
        '/userPlatformInformation',
        '/businessPushLog/index',
        '/company/user',
        '/company/group',
        '/company/index',
        '/smallProgramCompany/index',
        '/industrialChainEnterprise/index',
        '/userList/index',
        '/smallProgramUser/index',
        '/district/index',
        '/reportManagement/index',
        '/reportManagement/cityIndex',
        '/article/index',
        '/dataScreenManagement/index',
        '/trainingActivity/index',
        '/trainingCourse/index'
    ]

   if(arrList.indexOf(from.path) !== -1 && arrList.indexOf(to.path) !== -1){
      sessionStorage.removeItem('formInline')
      next()
   }
})

router.afterEach(() => {
    // finish progress bar
    // NProgress.done()
})
